import React, { useRef } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type PdfPreviewProps = {
  url: string;
  onPdfRendered: (data: ImageData) => void;
};

const PdfPreview: React.FC<PdfPreviewProps> = (
  props: PdfPreviewProps
): JSX.Element => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const canvasPreviewRef = useRef<HTMLCanvasElement>(null);
  let w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  w = Math.min(w - 50, 600);

  function onDocumentLoadSuccess() {
    const canvas: HTMLCanvasElement | undefined = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const previewCanvas = canvasPreviewRef.current;
        if (previewCanvas) {
          const ctx2 = previewCanvas.getContext('2d');
          if (ctx2) ctx2.putImageData(imgData, 0, 0);
        }
        props.onPdfRendered(imgData);
      }
    }
  }

  return (
    <div
      style={{
        marginTop: 30,
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: ' center',
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'none' }}>
        <Document file={props.url}>
          <Page
            pageNumber={1}
            canvasRef={canvasRef as React.LegacyRef<HTMLCanvasElement>}
            onRenderSuccess={onDocumentLoadSuccess}
          />
        </Document>
      </div>

      <Document file={props.url}>
        <Page width={w} pageNumber={1} />
      </Document>
    </div>
  );
};

export default PdfPreview;
