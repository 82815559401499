import React, { useEffect } from 'react';

export type PdfPreviewProps = {
  url: string;
  onImgRendered: (data: ImageData) => void;
};

const ImagePreview: React.FC<PdfPreviewProps> = (
  props: PdfPreviewProps
): JSX.Element => {
  const { url, onImgRendered } = props;

  useEffect(() => {
    const image = new Image();
    image.src = url;
    image.onload = function (ev) {
      const canvas = document.createElement('canvas');
      if (canvas) {
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(image, 0, 0);
          const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          onImgRendered(imgData);
        }
      }
    };
  }, [onImgRendered, url]);

  let w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  w = Math.min(w - 50, 600);

  return (
    <div
      style={{
        marginTop: 30,
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: ' center',
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'none' }}>
        <img src={url} style={{ width: 1024 * 4 }} alt='img-preview' />
      </div>
      <img src={url} style={{ maxWidth: w }} alt='img-preview' />
    </div>
  );
};

export default ImagePreview;
