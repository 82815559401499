import React, { useContext, useEffect, useMemo, useState } from 'react';

import { IntlProvider } from 'react-intl';
import { Direction } from '@material-ui/core/styles';

import ar from '../lang/ar.json';
import bg from '../lang/bg.json';
import ca from '../lang/ca.json';
import cs from '../lang/cs.json';
import de from '../lang/de.json';
import en from '../lang/en.json';
import es from '../lang/es.json';
import eu from '../lang/eu.json';
import fi from '../lang/fi.json';
import fr from '../lang/fr.json';
import gl from '../lang/gl.json';
import hr from '../lang/hr.json';
import he from '../lang/he.json';
import ka from '../lang/ka.json';
import it from '../lang/it.json';
import pl from '../lang/pl.json';
import pt from '../lang/pt.json';
import ro from '../lang/ro.json';
import ru from '../lang/ru.json';
import sk from '../lang/sk.json';
import sl from '../lang/sl.json';
import sv from '../lang/sv.json';
import uk from '../lang/uk.json';

export type LocaleContextData = {
  locale: string;
  direction: Direction;
  changeLocale: (s: string) => void;
};

export const LocaleContext = React.createContext<LocaleContextData | null>(
  null
);

const messages: Record<string, Record<string, string>> = {
  ar,
  bg,
  ca,
  cs,
  de,
  en,
  es,
  eu,
  fi,
  fr,
  gl,
  he,
  hr,
  it,
  ka,
  pl,
  pt,
  ro,
  ru,
  sk,
  sl,
  sv,
  uk,
};

const rtlLocales = ['ar', 'he'];

export const supportedLocales = Object.keys(messages);

interface LocaleProviderProps {
  locale: string;
}

export const LocaleProvider: React.FC<
  React.PropsWithChildren<LocaleProviderProps>
> = (props: React.PropsWithChildren<LocaleProviderProps>) => {
  const [locale, setLocale] = useState<string>(props.locale);
  const direction = useMemo<Direction>(() => {
    return rtlLocales.indexOf(locale) > -1 ? 'rtl' : 'ltr';
  }, [locale]);

  const changeLocale = (l: string) => {
    setLocale(l);
  };

  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  return (
    <LocaleContext.Provider value={{ direction, locale, changeLocale }}>
      <IntlProvider messages={messages[locale]} locale={locale}>
        {props.children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const useLocaleContext = (): LocaleContextData => {
  const ctx = useContext<LocaleContextData | null>(LocaleContext);
  if (ctx === null) {
    throw Error('useLocaleContext must be called in LocaleContextProvider');
  }

  return ctx;
};
