import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { LegalProvider, LocaleProvider, supportedLocales } from './context';
import ThemeDirectionProvider from './components/ThemeDirectionProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

let locale = navigator.language.split('-')[0];

if (supportedLocales.lastIndexOf(locale) < 0) {
  locale = 'en';
}

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <LocaleProvider locale={locale}>
      <ThemeProvider theme={theme}>
        <ThemeDirectionProvider>
          <LegalProvider>
            <SnackbarProvider hideIconVariant={true}>
              <App />
            </SnackbarProvider>
          </LegalProvider>
        </ThemeDirectionProvider>
      </ThemeProvider>
    </LocaleProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
