import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { scanImageData } from 'zbar.wasm';
import useNotice from '../../hooks/useNotice';
import { messages } from '../../messages';

export type ScannerProps = {
  onScan: (data: string) => void;
  device: MediaDeviceInfo;
  onClose: () => void;
};

function Scanner(props: ScannerProps): JSX.Element {
  const { onScan, device, onClose } = props;
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [tick, setTick] = useState(0);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const intl = useIntl();
  const notice = useNotice();

  const runVideo = async () => {
    // Get access to the camera!
    try {
      if (navigator.mediaDevices && device && !stream) {
        console.log(`create stream on device ${device?.deviceId}`);
        // Not adding `{ audio: true }` since we only want video now
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: device.deviceId,
            facingMode: 'environment',
          },
        });

        const video = videoRef.current;
        if (video) {
          video.setAttribute('playsinline', 'true');
          // video.setAttribute('controls', 'true');
          video.srcObject = newStream;
          await video.play();
          setStream(newStream);
        }
      }
    } catch (e) {
      console.error(e);
      notice.error(intl.formatMessage(messages.cameraError));
      onClose();
    }
  };

  const scan = useCallback(async () => {
    const video = videoRef.current;
    if (video) {
      const canvas = document.createElement('canvas');
      const width = video.videoWidth;
      const height = video.videoHeight;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, width, height);
        const imgData = ctx.getImageData(0, 0, width, height);
        return await scanImageData(imgData);
      }
    }
    return null;
  }, [videoRef]);

  React.useEffect(() => {
    void runVideo();
  });

  const handleScan = useCallback(
    async (currentTick: number) => {
      if (!!stream) {
        let queueTick = true;
        try {
          const res = await scan();

          if (res != null && res.length > 0) {
            queueTick = false;
            const tracks = stream.getTracks();
            tracks.forEach((t) => t.stop());

            onScan(res[0].decode());
            onClose();
          }
        } finally {
          if (queueTick) {
            setTimeout(() => {
              setTick(currentTick + 1);
            }, 200);
          }
        }
      }
    },
    [stream, onClose, onScan, scan]
  );

  useEffect(() => {
    handleScan(tick).catch(console.error);
  }, [tick, handleScan]);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10000,
        background: 'black',
      }}
    >
      <div
        style={{
          justifyContent: 'start',
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          position: 'relative',
          flexGrow: 1,
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <video
          id='video'
          autoPlay
          playsInline
          ref={videoRef}
          style={{
            width: '100%',
            height: '100%',
            display: 'block',
            margin: '0 auto',
          }}
        />

        <Button
          size='large'
          variant='contained'
          color='secondary'
          onClick={() => {
            if (stream) {
              const tracks = stream.getTracks();
              tracks.forEach((t) => t.stop());
            }
            onClose();
          }}
          style={{
            position: 'absolute',
            bottom: 50,
          }}
        >
          {intl.formatMessage(messages.scannerClose)}
        </Button>
      </div>
    </div>
  );
}

export default Scanner;
