import {
  createMuiTheme,
  Theme as AugmentedTheme,
} from '@material-ui/core/styles';

const theme: AugmentedTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#113293',
    },
    secondary: {
      main: '#FFCE00',
    },
  },
  shape: { borderRadius: 0 },
});

export default theme;
