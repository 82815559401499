import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  termOfUse: {
    id: 'Term of Use',
  },
  privacyPolicy: {
    id: 'Privacy Policy',
  },

  clickToAcknowledge: {
    id: 'clickToAcknowledge',
    defaultMessage: 'Continue',
  },

  agreementInfo: {
    id: 'agreementInfo',
    defaultMessage: `<p>By clicking Accept you acknowledge that YOUR PASS, s.r.o. as a personal data controller will process your personal data in accordance with our Privacy Policy.</p>
    <p>You also warrant that the data you are submitting using the QR code relate only to your person. You can revoke your consent at any time.</p>
    <p>For more information read our {privacyPolicyLink} and our {termOfUseLink}.</p>
    `,
  },

  consentPrivacyPolicyLink: {
    id: 'consentPrivacyPolicyLink',
    defaultMessage: '<a>Privacy Policy</a>',
  },

  consentTermOfUseLink: {
    id: 'consentTermOfUseLink',
    defaultMessage: '<a>Service Conditions and Definitions</a>',
  },

  agreement: {
    id: 'agreement',
    defaultMessage: 'I accept',
  },

  privacyPolicyLink: {
    id: 'privacyPolicyLink',
    defaultMessage: '<a>Privacy Policy</a>',
  },

  privacyPolicyLinkUri: {
    id: 'privacyPolicyLinkUri',
    defaultMessage: 'https://getcovidpass.eu/privacy-en',
  },

  termOfUseLink: {
    id: 'termOfUseLink',
    defaultMessage: '<a>Service Conditions and Definitions</a>',
  },

  termOfUseLinkUri: {
    id: 'termOfUseLinkUri',
    defaultMessage: 'https://getcovidpass.eu/terms-en',
  },

  constentTitle: {
    id: 'constentTitle',
    defaultMessage:
      ' Create a digital Covid Certificate Pass to your mobile wallet',
  },

  notFoundMessage: {
    id: 'notFoundMessage',
    defaultMessage:
      'The uploaded file does not contain a certificate or was not recognized. Try uploading a file in better quality',
  },

  mainTitle: {
    id: 'mainTitle',
    defaultMessage:
      'Create a digital Covid Certificate Pass to your mobile wallet',
  },

  scannerButtonTitle: {
    id: 'scannerButtonTitle',
    defaultMessage: 'Scan QR',
  },

  uploadButtonTitle: {
    id: 'uploadButtonTitle',
    defaultMessage: 'Upload PDF/JPG/PNG',
  },

  or: {
    id: 'or',
    defaultMessage: 'or',
  },

  noticeClose: {
    id: 'noticeClose',
    defaultMessage: 'Close',
  },

  scannerClose: {
    id: 'scannerClose',
    defaultMessage: 'Close',
  },

  cameraError: {
    id: 'cameraError',
    defaultMessage: 'Camera is not available',
  },

  createPassError: {
    id: 'createPassError',
    defaultMessage: 'Creating pass fail.',
  },

  invalidCertificate: {
    id: 'invalidCertificate',
    defaultMessage:
      'We were unable to process the code you have provided. It might not adhere to EU specification, please read <a>this article</a> to learn more.',
  },

  invalidCertificateLinkUrl: {
    id: 'invalidCertificateLinkUrl',
    defaultMessage: 'https://www.getcovidpass.eu/error?lang=en',
  },
});
