import React from 'react';
import { useLocaleContext } from '../../context';
import { makeStyles } from '@material-ui/core/styles';
import ar from '../../assets/ARA_2x.png';
import bg from '../../assets/BUL_2x.png';
import ca from '../../assets/CAT_2x.png';
import cs from '../../assets/CZE_2x.png';
import de from '../../assets/DEU_2x.png';
import en from '../../assets/USA_2x.png';
import es from '../../assets/ESP_2x.png';
import eu from '../../assets/EUS_2x.png';
import fi from '../../assets/FIN_2x.png';
import fr from '../../assets/FRA_2x.png';
import gl from '../../assets/GLG_2x.png';
import he from '../../assets/ISR_2x.png';
import hr from '../../assets/HRV_2x.png';
import it from '../../assets/ITA_2x.png';
import ka from '../../assets/GEO_2x.png';
import pl from '../../assets/POL_2x.png';
import pt from '../../assets/PRT_2x.png';
import ro from '../../assets/ROU_2x.png';
import ru from '../../assets/RUS_2x.png';
import sk from '../../assets/SVK_2x.png';
import sl from '../../assets/SVN_2x.png';
import sv from '../../assets/SWE_2x.png';
import uk from '../../assets/UKR_2x.png';
import { Button, Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  btn: {
    color: 'white',
  },
  languageImg: {
    height: 20,
    paddingRight: '6px',
  },
}));

const languages: Record<string, { img: string; title: string }> = {
  ar: {
    img: ar,
    title: 'AR',
  },
  bg: {
    img: bg,
    title: 'BG',
  },
  ca: {
    img: ca,
    title: 'CA',
  },
  cs: {
    img: cs,
    title: 'CS',
  },
  de: {
    img: de,
    title: 'DE',
  },
  en: {
    img: en,
    title: 'EN',
  },
  es: {
    img: es,
    title: 'ES',
  },
  eu: {
    img: eu,
    title: 'EU',
  },
  fi: {
    img: fi,
    title: 'FI',
  },
  fr: {
    img: fr,
    title: 'FR',
  },
  gl: {
    img: gl,
    title: 'GL',
  },
  he: {
    img: he,
    title: 'HE',
  },
  hr: {
    img: hr,
    title: 'HR',
  },
  ka: {
    img: ka,
    title: 'KA',
  },
  it: {
    img: it,
    title: 'IT',
  },
  pl: {
    img: pl,
    title: 'PL',
  },
  pt: {
    img: pt,
    title: 'PT',
  },
  ro: {
    img: ro,
    title: 'RO',
  },
  ru: {
    img: ru,
    title: 'RU',
  },
  sk: {
    img: sk,
    title: 'SK',
  },
  sl: {
    img: sl,
    title: 'SL',
  },
  sv: {
    img: sv,
    title: 'SV',
  },
  uk: {
    img: uk,
    title: 'UK',
  },
};

function LanguageSelect(): JSX.Element {
  const localeContext = useLocaleContext();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        className={classes.btn}
        endIcon={<ExpandMoreIcon />}
      >
        <img
          alt={languages[localeContext.locale].title}
          src={languages[localeContext.locale].img}
          className={classes.languageImg}
        />
        <span>{languages[localeContext.locale].title}</span>
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(languages).map((l) => {
          return (
            <MenuItem
              key={l}
              onClick={() => {
                localeContext.changeLocale(l);
                handleClose();
              }}
            >
              <img
                alt={languages[l].title}
                src={languages[l].img}
                className={classes.languageImg}
              />
              <span>{languages[l].title}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default LanguageSelect;
