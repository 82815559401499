import React, { useCallback, useMemo } from 'react';
import { makeStyles, Button, SnackbarOrigin } from '@material-ui/core';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { messages } from '../messages';

export type NotifyFce = (str: string) => void;

const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: '1.25rem',
  },
}));

export default function useNotice(): {
  info: NotifyFce;
  error: NotifyFce;
} {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const intl = useIntl();

  const action = useCallback(
    (key: SnackbarKey) => (
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
        style={{ color: 'white', pointerEvents: 'auto' }}
      >
        {intl.formatMessage(messages.noticeClose)}
      </Button>
    ),
    [closeSnackbar, intl]
  );

  const opts = useMemo(
    () => ({
      persist: true,
      autoHideDuration: 10000,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      } as SnackbarOrigin,
      action,
    }),
    [action]
  );

  const error = useCallback(
    (str: string) => {
      enqueueSnackbar(<p className={classes.message}>{str}</p>, {
        ...opts,

        variant: 'error',
      });
    },
    [classes, enqueueSnackbar, opts]
  );

  const info = useCallback(
    (str: string) => {
      enqueueSnackbar(<p className={classes.message}>{str}</p>, {
        ...opts,
        variant: 'info',
      });
    },
    [classes, enqueueSnackbar, opts]
  );

  return { info, error };
}
