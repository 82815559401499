import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from '../../messages';
import { useContext } from 'react';
import { LegalContext, useLocaleContext } from '../../context';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    container: {
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      flexGrow: 1,
      display: 'flex',
      width: '100%',
    },
  })
);

const LegalDialog: React.FC = () => {
  const [show, setShow] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);
  const { direction } = useLocaleContext();
  const classes = useStyles();
  const legalCtx = useContext(LegalContext);

  const intl = useIntl();

  const privacyPolicyLinkUri = intl.formatMessage(
    messages.privacyPolicyLinkUri
  );

  const termOfUseLinkUri = intl.formatMessage(messages.termOfUseLinkUri);

  const privacyPolicyLink = intl.formatMessage(
    messages.consentPrivacyPolicyLink,
    {
      a: (chunks: string[]) => {
        return `<a href="${privacyPolicyLinkUri}" target="_blank" rel="noopener noreferrer">${chunks.join(
          ','
        )}</a>`;
      },
    }
  );

  const termOfUseLink = intl.formatMessage(messages.consentTermOfUseLink, {
    a: (chunks) => {
      return `<a href="${termOfUseLinkUri}" target="_blank" rel="noopener noreferrer">${chunks.join(
        ','
      )}</a>`;
    },
  });

  let agreementInfoHtml = intl.formatMessage(messages.agreementInfo, {
    p: (chunks) => {
      return `<p>${chunks.join(',')}</p>`;
    },
    privacyPolicyLink,
    termOfUseLink,
  });

  agreementInfoHtml = agreementInfoHtml
    .replace('{termOfUseLink}', termOfUseLink)
    .replace('{privacyPolicyLink}', privacyPolicyLink);

  const agreement = intl.formatMessage(messages.agreement);

  const title = intl.formatMessage(messages.constentTitle);

  return (
    <Dialog
      dir={direction}
      open={show}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <span dangerouslySetInnerHTML={{ __html: agreementInfoHtml }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FormGroup row className={classes.container}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
                name='checkedA'
              />
            }
            label={agreement}
          />
        </FormGroup>
      </DialogActions>
      <DialogActions>
        <Button
          style={{ color: 'white' }}
          disabled={!checked}
          color='primary'
          variant='contained'
          onClick={(): void => {
            setShow(false);
            legalCtx.setAgreed(true);
          }}
        >
          <FormattedMessage {...messages.clickToAcknowledge} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LegalDialog;
