import React, { ReactNode, useCallback, useRef } from 'react';
import '../App.css';
import ScannerButton from './Scanner/ScannerButton';
import FileForm from './Upload/FileForm';
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../messages';
import useNotice from '../hooks/useNotice';

type CertResponse = {
  passUrl: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    title: {
      textAlign: 'center',
      fontSize: '2.5em',
      paddingBottom: theme.spacing(3),
    },
    box: {
      paddingTop: theme.spacing(2),
      textAlign: 'center',
      paddingBottom: theme.spacing(2),
    },
    divider: {
      textAlign: 'center',
    },
    error: {
      background: theme.palette.error.dark,
      color: 'white',
      borderRadius: '4px',
      padding: '1em',
      '& a': {
        color: 'white',
      },
    },
  })
);

function App(): JSX.Element {
  const [pending, setPending] = useState(false);
  const classes = useStyles();
  const { error } = useNotice();
  const errorMessage = useRef<ReactNode>();

  const intl = useIntl();

  const handleOnFoundQrCode = useCallback(
    async (str: string) => {
      if (str.startsWith('HC1:')) {
        setPending(true);
        try {
          const response = await fetch('/cert', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
              'Content-Type': 'text/plain',
            },
            body: str,
          });
          if (response.status === 400) {
            const responseText = await response.text();
            if (responseText === 'invalid cert') {
              errorMessage.current = intl.formatMessage(
                messages.invalidCertificate,
                {
                  a: function invalidCertLink(chunks) {
                    return (
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={intl.formatMessage(
                          messages.invalidCertificateLinkUrl
                        )}
                      >
                        {chunks}
                      </a>
                    );
                  },
                }
              );
            } else {
              throw new Error('Failed to create certificate');
            }
          } else {
            const json = (await response.json()) as CertResponse;
            if (json.passUrl) {
              window.location.href = json.passUrl;
            } else {
              throw new Error('Failed to retrieve pass URL');
            }
          }
        } catch (e) {
          console.error(e);
          error(intl.formatMessage(messages.createPassError));
        }

        setPending(false);
      } else {
        error(intl.formatMessage(messages.notFoundMessage));
      }
    },
    [intl, error, errorMessage, setPending]
  );

  const handleNotFound = useCallback(() => {
    error(intl.formatMessage(messages.notFoundMessage));
  }, [error, intl]);

  return (
    <>
      <Typography variant={'h1'} className={classes.title}>
        {intl.formatMessage(messages.mainTitle)}
      </Typography>
      <Backdrop className={classes.backdrop} open={pending}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid container>
        <Grid item xs={12} className={classes.box}>
          <ScannerButton onScan={handleOnFoundQrCode} pending={pending} />
        </Grid>
        <Grid item xs={12} className={classes.divider}>
          {intl.formatMessage(messages.or)}
        </Grid>
        <Grid item xs={12} className={classes.box}>
          <FileForm
            onFound={handleOnFoundQrCode}
            onNotFound={handleNotFound}
            pending={pending}
          />
        </Grid>
      </Grid>
      {errorMessage.current && (
        <div className={classes.error}>{errorMessage.current}</div>
      )}
    </>
  );
}

export default App;
