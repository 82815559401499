import React, { useCallback, useRef, useState } from 'react';
import { scanImageData } from 'zbar.wasm';
import PdfPreview from './PdfPreview';
import ImgPreview from './ImgPreview';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { Button } from '@material-ui/core';
import { messages } from '../../messages';
import { useIntl } from 'react-intl';
export type FileFormProps = {
  onFound: (data: string) => void;
  onNotFound: () => void;
  pending: boolean;
};

function FileForm(props: FileFormProps): JSX.Element {
  const [dataUri, setDataUri] = useState<string | null>(null);
  const [type, setType] = useState<null | 'pdf' | 'img'>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const { onFound, onNotFound, pending } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDataUri(null);
      setType(null);
      if (event.target.files && event.target.files[0]) {
        const fileReader = new FileReader();
        const isFilePDF = event.target.files[0].name.endsWith('pdf');
        fileReader.onloadend = (f) => {
          const content = fileReader.result;
          if (content) {
            setDataUri(content.toString());
            if (isFilePDF) {
              setType('pdf');
            } else {
              setType('img');
            }
          }
        };
        fileReader.readAsDataURL(event.target.files[0] as Blob);
      }
    },
    [setDataUri, setType]
  );

  const handleImgLoaded = useCallback(
    async (d: ImageData) => {
      const res = await scanImageData(d);
      if (res.length > 0) {
        onFound(res[0].decode());
      } else {
        onNotFound();
      }
    },
    [onFound, onNotFound]
  );

  return (
    <div className='form'>
      <form>
        <Button
          disabled={pending}
          variant={'contained'}
          color='secondary'
          startIcon={<UploadIcon />}
          onClick={() => {
            const i = inputRef.current;
            if (i) {
              i.click();
            }
          }}
        >
          {intl.formatMessage(messages.uploadButtonTitle)}
        </Button>
        <input
          ref={inputRef}
          type='file'
          accept='image/jpeg,image/gif,image/png,application/pdf'
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </form>
      {type === 'img' && dataUri && (
        <ImgPreview url={dataUri} onImgRendered={handleImgLoaded} />
      )}
      {type === 'pdf' && dataUri && (
        <PdfPreview url={dataUri} onPdfRendered={handleImgLoaded} />
      )}
    </div>
  );
}

export default FileForm;
