import React, { useState } from 'react';
import Scanner from './Scanner';
import { Button, CircularProgress } from '@material-ui/core';
import QrCodeIcon from '@material-ui/icons/PhotoCamera';
import { messages } from '../../messages';
import { useIntl } from 'react-intl';

export type ScannerButtonProps = {
  onScan: (data: string) => void;
  pending: boolean;
};

function ScannerButton(props: ScannerButtonProps): JSX.Element {
  const { onScan } = props;
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [device, setDevice] = useState<MediaDeviceInfo | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const intl = useIntl();

  React.useEffect(() => {
    const detectDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      setDevices(devices);
      if (devices.length > 0) {
        setDevice(devices[0]);
      }
    };

    void detectDevices()
      .catch(setError)
      .finally(() => {
        setPending(false);
      });
  }, []);

  const videoDevices = devices.filter((d) => d.kind === 'videoinput');

  const icon = pending ? <CircularProgress /> : <QrCodeIcon />;

  return (
    <div className='ScannerButton'>
      {device && (
        <Button
          variant={'contained'}
          color='secondary'
          disabled={pending || props.pending || !!error}
          onClick={() => {
            setOpen(true);
          }}
          startIcon={icon}
        >
          {intl.formatMessage(messages.scannerButtonTitle)}
        </Button>
      )}
      {open && device && (
        <Scanner
          device={device}
          onScan={onScan}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}

      {error && <p>Camera is not available</p>}
      <div style={{ display: 'none' }}>
        {videoDevices.length > 1 &&
          videoDevices.map((d) => {
            return (
              <button
                key={d.deviceId + d.label}
                onClick={() => {
                  setDevice(d);
                }}
              >
                {d.label} {d.kind}
              </button>
            );
          })}
      </div>
    </div>
  );
}

export default ScannerButton;
