import React, { useState } from 'react';

export type LegalContextData = {
  agreed: boolean;
  setAgreed: (b: boolean) => void;
};

export const LegalContext = React.createContext<LegalContextData>({
  agreed: false,
  setAgreed: () => {
    throw new Error('not implemented');
  },
});

export const LegalProvider: React.FC<React.PropsWithChildren<{}>> = (
  props: React.PropsWithChildren<{}>
) => {
  const [agreed, setAgreed] = useState<boolean>(false);

  return (
    <LegalContext.Provider value={{ agreed, setAgreed }}>
      {props.children}
    </LegalContext.Provider>
  );
};
