import React, { useContext } from 'react';
import './App.css';
import LegalDialog from './components/LegalDialog/LegalDialog';
import { LegalContext } from './context';
import Main from './components/Main';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { AppBar, Toolbar } from '@material-ui/core';
import logo from './assets/logo.png';

import LanguageSelect from './components/LanguageSelect/LanguageSelect';
import { useIntl } from 'react-intl';
import { messages } from './messages';
import ThemeDirectionProvider from './components/ThemeDirectionProvider';

const useStyles = makeStyles((theme) => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    color: '#fff',
    textDecoration: 'none',

    '& a': {
      textDecoration: 'none',
    },
  },
  logo: {
    height: theme.spacing(10),
    padding: theme.spacing(1),
  },
  titlePrimary: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  titleSecondary: {
    fontSize: '1.25rem',
  },
  content: {
    marginBottom: 100,
    marginTop: 20,
    flexGrow: 1,
    display: 'flex',
  },
  footer: {
    height: 100,
    bottom: 0,
    background: '#FFCE00',
    width: '100%',
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    flex: 1,
    height: '100%',
  },
  footerLink: {
    padding: theme.spacing(1),
  },
}));

function App(): JSX.Element {
  const { agreed } = useContext(LegalContext);
  const classes = useStyles();
  const intl = useIntl();
  const privacyPolicyLinkUri = intl.formatMessage(
    messages.privacyPolicyLinkUri
  );

  const termOfUseLinkUri = intl.formatMessage(messages.termOfUseLinkUri);

  const privacyPolicyLink = intl.formatMessage(messages.privacyPolicyLink, {
    a: (chunks: string[]) => {
      return `<a href="${privacyPolicyLinkUri}" target="_blank" rel="noopener noreferrer">${chunks.join(
        ','
      )}</a>`;
    },
  });

  const termOfUseLink = intl.formatMessage(messages.termOfUseLink, {
    a: (chunks) => {
      return `<a href="${termOfUseLinkUri}" target="_blank" rel="noopener noreferrer">${chunks.join(
        ','
      )}</a>`;
    },
  });

  return (
    <div className={classes.app}>
      <ThemeDirectionProvider forceDirection='ltr'>
        <AppBar position='static' dir='ltr'>
          <Container component='main' maxWidth='md'>
            <Toolbar>
              <a href='https://getcovidpass.eu'>
                <img src={logo} className={classes.logo} alt='Logo' />
              </a>
              <div className={classes.title}>
                <a href='https://getcovidpass.eu'>
                  <div className={classes.title}>
                    <div className={classes.titlePrimary}>DIGITAL</div>
                    <div className={classes.titleSecondary}>Covid Pass</div>
                  </div>
                </a>
              </div>

              <LanguageSelect />
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeDirectionProvider>
      <Container component='main' maxWidth='md' className={classes.content}>
        <div className={classes.paper}>
          <LegalDialog />
          {agreed && <Main />}
        </div>
      </Container>
      <footer className={classes.footer}>
        <Container
          component='main'
          maxWidth='md'
          className={classes.footerContent}
        >
          <span
            className={classes.footerLink}
            dangerouslySetInnerHTML={{ __html: privacyPolicyLink }}
          />
          <span
            className={classes.footerLink}
            dangerouslySetInnerHTML={{ __html: termOfUseLink }}
          />
        </Container>
      </footer>
    </div>
  );
}

export default App;
