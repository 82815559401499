import React, { PropsWithChildren, useMemo } from 'react';
import {
  createMuiTheme,
  Direction,
  ThemeOptions,
} from '@material-ui/core/styles';

import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { useLocaleContext } from '../context';
import { create } from 'jss';
import rtl from 'jss-rtl';

const ltrTheme: ThemeOptions = {
  direction: 'ltr',
};

const rtlTheme: ThemeOptions = {
  direction: 'rtl',
};

type ThemeProviderProps = PropsWithChildren<{ forceDirection?: Direction }>;

const ThemeProvider: React.FC<ThemeProviderProps> = (
  props: ThemeProviderProps
) => {
  const { children, forceDirection } = props;
  const { direction: currentDirection } = useLocaleContext();

  const direction = useMemo(() => {
    return forceDirection || currentDirection;
  }, [currentDirection, forceDirection]);

  const theme = useMemo(() => {
    return direction === 'rtl' ? rtlTheme : ltrTheme;
  }, [direction]);

  const jss = useMemo(() => {
    return create({
      plugins: [...jssPreset().plugins, rtl({ enabled: direction === 'rtl' })],
    });
  }, [direction]);

  return (
    <MuiThemeProvider
      theme={(outerTheme) =>
        createMuiTheme({
          ...outerTheme,
          ...theme,
        })
      }
    >
      <StylesProvider jss={jss}>{children}</StylesProvider>;
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
